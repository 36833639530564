import React, { useEffect, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import {
  FieldSet,
  Subtitle,
  TitleSet,
  Select,
  Card,
  Input,
  Checkbox,
  FORM_GRID,
} from '../inputs';
import { CountryCodeList } from './data';

export default function Fields({ values }) {
  const form = useForm();
  const [newsletter, setNewsletter] = useState(values.newsletter || false);
  const [professionalAccess, setProfessionalAccess] = useState(values.professionalAccess || false);
  const [investmentInformation, setInvestmentInformation] = useState(values.investmentInformation || false);

  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  useEffect(() => {
    form.change('newsletter', newsletter);
    form.change('professionalAccess', professionalAccess);
    form.change('investmentInformation', investmentInformation);
  }, [newsletter, professionalAccess, investmentInformation, form]);

  const isNewsletterOnly = newsletter && !professionalAccess && !investmentInformation;
  const isProfessionalOrInvestment = professionalAccess || investmentInformation;

  return (
    <>
      <FieldSet>
        <TitleSet>
          <Subtitle>News updates</Subtitle>
        </TitleSet>
        {/* News updates */}
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <div className='flex flex-col space-y-4 mb-8'>
            <p className='font-bold'>Please choose the type of access and information you would like:</p>
            <div className='flex justify-start space-x-8'>
              <Field name="newsletterOnly" type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    input={input}
                    meta={meta}
                    size={FORM_GRID[0]}
                    disabled={false}
                    required={false}
                    checked={newsletter}
                    onChange={handleCheckboxChange(setNewsletter)}
                  >
                    Newsletter
                  </Checkbox>
                )}
              </Field>
              <Field name="professionalAccess" type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    input={input}
                    meta={meta}
                    label="Professional Access"
                    checked={professionalAccess}
                    onChange={handleCheckboxChange(setProfessionalAccess)}
                  >
                    Professional Access
                  </Checkbox>
                )}
              </Field>
              <Field name="investmentInformation" type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    input={input}
                    meta={meta}
                    checked={investmentInformation}
                    label="Investment Information"
                    onChange={handleCheckboxChange(setInvestmentInformation)}
                  >
                    Investment Information
                  </Checkbox>
                )}
              </Field>
            </div>
          </div>

          <p className='font-bold'>Enter email for Newsletter only - Scroll down to click submit</p>
          <Card>
            <Field name='first_name'>
              {({ input, meta }) => (
                <Input
                  input={input}
                  meta={meta}
                  label='First name *'
                  type='text'
                  size={FORM_GRID[3]}
                  placeholder=''
                  autoComplete='off'
                  required={newsletter === true || investmentInformation === true}
                  disabled={!isNewsletterOnly}
                ></Input>
              )}
            </Field>
            <Field name='last_name'>
              {({ input, meta }) => (
                <Input
                  input={input}
                  meta={meta}
                  label='Last name *'
                  type='text'
                  size={FORM_GRID[3]}
                  placeholder=''
                  autoComplete='off'
                  required={newsletter === true || investmentInformation === true}
                  disabled={!isNewsletterOnly}
                ></Input>
              )}
            </Field>
            <Field name='email_update'>
              {({ input, meta }) => (
                <Input
                  input={input}
                  meta={meta}
                  label='Email *'
                  type='email'
                  size={FORM_GRID[0]}
                  placeholder=''
                  autoComplete='off'
                  required={newsletter === true || investmentInformation === true}
                  disabled={!isNewsletterOnly}
                ></Input>
              )}
            </Field>
          </Card>
        </div>
      </FieldSet>

      <FieldSet>
        <TitleSet>
          <Subtitle>Professional Information and/or Investment Information</Subtitle>
        </TitleSet>
        {/* Professional Information and/or Investment Information */}
        <Card>
          <Field name='title'>
            {({ input, meta }) => (
              <Select
                input={input}
                meta={meta}
                label='Title'
                size={FORM_GRID[2]}
                required={false}
                disabled={!isProfessionalOrInvestment}
              >
                <option value=''>Select one</option>
                <option value='MD'>MD</option>
                <option value='PhD'>PhD</option>
                <option value='MPH'>MPH</option>
                <option value='RN'>RN</option>
                <option value='MSN'>MSN</option>
              </Select>
            )}
          </Field>
          <Field name='firstName'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='First name *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!isProfessionalOrInvestment}
              ></Input>
            )}
          </Field>
          <Field name='lastName'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='Last name *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!isProfessionalOrInvestment}
              ></Input>
            )}
          </Field>
          <Field name='email'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='Email *'
                type='email'
                size={FORM_GRID[0]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!isProfessionalOrInvestment}
              ></Input>
            )}
          </Field>
          <Field name='professionalCat'>
            {({ input, meta }) => (
              <Select
                input={input}
                meta={meta}
                label='Professional category *'
                size={FORM_GRID[3]}
                required={professionalAccess === true || investmentInformation === true}
                disabled={!isProfessionalOrInvestment}
              >
                <option value=''>Select one</option>
                <option value='Physician'>Physician</option>
                <option value='Allied Professional'>Allied Professional</option>
                <option value='Healthcare Administrator'>
                  Healthcare Administrator
                </option>
                <option value='Patient or Caregiver'>
                  Patient or Caregiver
                </option>
                <option value='Industry Affiliate'>Industry Affiliate</option>
                <option value='Media and Communications'>
                  Media and Communications
                </option>
                <option value='Investor'>Investor</option>
                <option value='Other'>Other</option>
              </Select>
            )}
          </Field>
          <Field name='primarySpecialty'>
            {({ input, meta }) => (
              <Select
                input={input}
                meta={meta}
                label='Primary specialty *'
                size={FORM_GRID[3]}
                required={professionalAccess === true || investmentInformation === true}
                disabled={!isProfessionalOrInvestment}
              >
                <option value=''>Select one</option>
                <option value='Electrophysiology'>Electrophysiology</option>
                <option value='Cardiology'>Cardiology</option>
                <option value='Other Medical'>Other Medical</option>
                <option value='Non-medical'>Non-medical</option>
              </Select>
            )}
          </Field>
          <Field name='primaryHospital'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='Primary affiliation/employer *'
                type='text'
                size={FORM_GRID[3]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!isProfessionalOrInvestment}
              ></Input>
            )}
          </Field>
          <Field name='countryCode'>
            {({ input, meta }) => (
              <Select
                input={input}
                meta={meta}
                label='Country *'
                size={FORM_GRID[3]}
                required={professionalAccess === true || investmentInformation === true}
                disabled={!isProfessionalOrInvestment}
              >
                <option value=''>Select a country</option>
                {Object.entries(CountryCodeList).map((country) => (
                  <option value={country[0]} key={country[0]}>{country[1]}</option>
                ))}
              </Select>
            )}
          </Field>
          <Field name='city'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='City *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!isProfessionalOrInvestment}
              ></Input>
            )}
          </Field>
          <Field name='state'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='State/Province *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!isProfessionalOrInvestment}
              ></Input>
            )}
          </Field>
          <Field name='postalCode'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='Zip/Postal Code *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!isProfessionalOrInvestment}
              ></Input>
            )}
          </Field>
        </Card>
      </FieldSet>

      <FieldSet>
        <TitleSet>
        </TitleSet>
        <Card>
          {newsletter && (
            <Field name='receivingUpdates' type='radio' value={'standard'}>
              {({ input, meta }) => (
                <Checkbox
                  input={input}
                  meta={meta}
                  size={FORM_GRID[0]}
                  label={'Save and Unpublish'}
                  required={newsletter}
                >
                  Yes, I agree to receive the updates on new educational content posted by Adagio Medical,
                  as well as Adagio Medical news, scientific and general product updates to the e-mail
                  address provided above. Consent to the mailing can be withdrawn at any time
                  (by e-mail to web_request@adagiomedical.com or to the contact details given in the legal notice).
                  The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.
                </Checkbox>
              )}
            </Field>
          )}
          {professionalAccess && (
            <Field name='receivingTechnicalInfo' type='radio' value={'advance'}>
              {({ input, meta }) => (
                <Checkbox
                  input={input}
                  meta={meta}
                  size={FORM_GRID[0]}
                  label={'Save and Unpublish'}
                  required={professionalAccess}
                >
                  I would like to receive the access to in-depth technical information on company products.
                  I understand that such information is intended for qualified medical professionals and
                  the access to such information is restricted based on the laws and regulations in the country of my residence.
                  I understand that Adagio Medical retains the right to restrict the access to such information based on
                  the information I provided, in particular if I do not meet the access requirements.
                  You can find more information at our <a className='text-primary-blue' href='/privacy-policy' target='_blank'>privacy policy</a>
                </Checkbox>
              )}
            </Field>
          )}
          {investmentInformation && (
            <Field name='receivingInvestor' type='radio' value={'investor'}>
              {({ input, meta }) => (
                <Checkbox
                  input={input}
                  meta={meta}
                  size={FORM_GRID[0]}
                  label={'Save and Unpublish'}
                  required={investmentInformation}
                >
                  Yes, I agree to receive investor information posted by Adagio Medical to the e-mail address provided above. 
                  Consent to the mailing can be withdrawn at any time (by e-mail to web_request@adagiomedical.com or to the contact details given in the legal notice). 
                  The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal
                </Checkbox>
              )}
            </Field>
          )}
        </Card>
      </FieldSet>
    </>
  );
}
