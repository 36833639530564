import React from 'react';
import FormSubmit from '../FormSubmit';
import Fields from './Fields';
import { sendRegistation } from '../../../store/slices/authSlice';

const getReqBody = (values) => {
	let roleArray = [];
	if (values.newsletter) roleArray.push('Newsletter');
	if (values.professionalAccess) roleArray.push('Professional');
	if (values.investmentInformation) roleArray.push('Investor');

	return {
		email: values.email,
		title: values.title,
		firstName: values.firstName || values.first_name,
		lastName: values.lastName || values.last_name,
		professionalCat: values.professionalCat,
		primarySpecialty: values.primarySpecialty,
		primaryHospital: values.primaryHospital,
		countryCode: values.countryCode,
		city: values.city,
		state: values.state,
		postalCode: values.postalCode,
		receiving: values.receiving,
		email_update: values.email_update,
		role: roleArray,
		newsletter: values.newsletter,
		professionalAccess: values.professionalAccess,
		investmentInformation: values.investmentInformation
	};
};
const buttonLabel = 'Register';
const redirectPath = '';

const RegisterForm = ({ setRedirectPath }) => {
	return (
		<FormSubmit
			component={Fields}
			getReqBody={getReqBody}
			submitAction={sendRegistation}
			buttonLabel={buttonLabel}
			redirectPath={redirectPath}
			setRedirectPath={setRedirectPath}
			initialValues={{ receiving: 'standard' }}
		/>
	);
};

export default RegisterForm;
